.optionsInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4rem 1rem;
  color: #292929;
}

.optionsInfoSubContainer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 70%;
}

.optionsInfoHeading {
  font-size: 49px;
  font-weight: 400;
}

.optionsInfoContentContainer {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 2rem;
}

.optionsInfoPointContainer {
  display: flex;
  width: 33.33%;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 0.5rem;
}
.optionsPointHeading {
  font-size: 20px;
  font-weight: 500;
}

.optionsPointSubheading {
  color: #5d5d5d;
}

.messageContainer {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid #000;
  border-radius: 4px;
}

.messageHeading {
  font-size: 24px;
  font-weight: 400;
}

button {
  border: 1.25px solid #000;
  background: #f99c38;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .optionsInfoContainer {
    padding: 2rem;
  }

  .optionsInfoSubContainer {
    width: 100%;
  }

  .optionsInfoHeading {
    font-size: 34px;
    line-height: 1.25;
  }

  .optionsInfoContentContainer {
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 0.25rem;
  }

  .optionsInfoPointContainer {
    width: 100%;
  }

  .optionsPointHeading {
    font-size: 18px;
  }

  .messageContainer {
    padding:1rem 0.5rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .messageHeading {
    font-size: 16px;
    font-weight: 400;
  }

  button {
    border: 1.25px solid #000;
    background: #f99c38;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
}
