.aboutContainer{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 4rem 1rem;
    padding-top: 2rem;
}

.aboutContainerAshfaq{
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 4rem 1rem;
    gap: 2%;
}

.aboutSubContainerAshfaq{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: 
    center;
    gap: 2rem;
    width: 48%;
}

.aboutSubContainer{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    gap: 2rem;
}

.aboutHeading{
    font-size:49px;
    font-weight: 400;
    line-height: 1.25;
}

.aboutSubHeading{
    font-size:20px;
    font-weight: 400;
    line-height: 1.5;
    color: #5d5d5d;
}

.aboutImage {
    border-radius: 10px;
}

.aboutImage > img {
    width: 300px;
    aspect-ratio: 1 / 1;
}


@media screen and (max-width: 564px) {
    .aboutContainer{
        width: 100%;
        padding: 4rem 2rem;
    }
    
    .aboutSubContainer{
        width: 100%;
        gap: 2rem;
    }
    
    .aboutHeading{
        font-size:34px;
    }
    
    .aboutSubHeading{
        font-size:18px;
    }
    
    .aboutImage{
        width: 100%;
        height: 240px;
        border-radius: 10px;
        background: #000;
    }   
}