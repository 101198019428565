ul {
    margin: 0;
}

ul.dashed {
    list-style-type: none;
}

ul.dashed>li {
    text-indent: -5px;
}

ul.dashed>li:before {
    content: "- ";
    font-weight: 700;
    text-indent: -5px;
}