.footerContainer {
  background: #fbfaf8;
  padding: 4rem 2rem;
  display: flex;
  align-items: start;
  width: 100%;
  padding-bottom: 8rem;
}

.footerSubContainer {
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  width: 100%;
}
.footerOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 0.25rem;
  font-size: 16px;
  font-weight:400;
}

.tabuddyHeading{
    font-weight:600;
    color: #191919;
}

.footerOption{
    cursor: pointer;
    color: #5D5D5D;
}

.footerSocials{
    display: flex;
    gap: 1rem;
}
