.outreachMainContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background: #fff;
  height: 96%;
  width: 96%;
  border-radius: 10px 0 10px 0;
}

.step1__top__heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  height: 64px;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-bottom: 1.5px solid #f2f2f2;
}

.subheading {
  margin: 0;
  font-size: 10px;
}

.buttonContainer {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.followupContainer {
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 30vh;
  flex-direction: column;
  gap: 2rem;
  background: #f7f8fc;
  padding-bottom: 2rem;
}

.followUpmessageHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.followUpWhatsappMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.followUpEmailMessageContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.followupcount {
  font-size: 10px;
  text-align: center;
  color: #fff;
  background: #0778bd;
  padding: 0.35rem 0.5rem;
  border-radius: 20px;
  text-align: center;
}

.followupcount > span {
  font-size: 8px;
  font-weight: bold;
  border-radius: 50%;
  border: 1.5px solid #fff;
  padding: 3px 6px;
  margin-right: 5px;
  font-weight: 700;
}

.followUpEmailContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 0 0.25rem;
  gap: 0.5rem;
  background: #f7f8fc;
  margin-bottom: 0.15rem;
  min-height: 70%;
  position: relative;
}

.emailinitialmessagecontainer {
  min-height: 150px;
  width: 90%;
  background: #fff;
  padding: 0 0.25rem 0.25rem 0.25rem;
}

.emailContainerHeading {
  width: 95%;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  font-size: 8px;
}

.emailContentRightHeading {
  font-size: 10px;
  display: flex;
  gap: 0.5rem;
}

.emailContentLeftHeading {
  display: flex;
  gap: 5px;
}

.emailFieldsContainer {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  gap: 0.25rem;
}

.message2heading {
  margin-bottom: 1rem;
}

.arrow {
  margin-top: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.straightline {
  border-right: 2px solid #000;
  height: 20px;
  margin-right: 0.5px;
  position: absolute;
  bottom: 10px;
}

.messageButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.modal {
  position: absolute;
  top: 10%;
  left: 25%;
  background: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  width: 50%;
  z-index: 99;
  padding-top: 2rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.432); /* Add this for box shadow */
}

-------------------------------------------- .straightArrowDirection3 {
  margin-left: 160px;
  margin-bottom: 20px;
}

.straightArrowDirection2 {
  margin-left: 250px;
  margin-bottom: 20px;
}

.whatsappMessageBox2 {
  position: absolute;
  top: 130px;
  right: 30px;
}

.whatsappMessageBox3 {
  top: 330px;
  left: 30px;
  position: absolute;
}

.whatsappMessageBoxheading {
  margin-bottom: 2rem;
}

.essentaial_container {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 3px dotted #f2f2f2;
}

.tagsContainer_heading {
  display: block;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  width: calc(100%- 2rem);
  padding: 1rem;
  border: 2px solid #f2f2f2;
}

.semiTagContainer {
  border: 2px solid #fff;
  padding: 0.4rem 0.8rem;
}

.semiTagContainerBorder {
  border: 2px dotted #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.4rem 0.8rem;
  border-radius: 2px;
}

.buttonContainerStep1_2 {
  width: 95%;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.booleanGeneratedModalContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 2px solid #f2f2f2;
  padding: 1rem;
}

.booleanSvgBG {
  text-align: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #dff6ee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalbuttonContainer {
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: flex-end;
}

.jobTitlesContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 1rem;
  font-size: 18px;
  color: #1a1a1a;
  font-weight: 500;
}

.sidebarContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  gap: 2px;
}

.templateContainer {
  height: calc(100% - 150.87px);
  overflow-y: hidden;
  border-right: 1.5px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttonContainerSidebar {
  width: 100%;
  gap: 2px;
  padding: 0.05rem 0.35rem;
  display: flex;
  justify-content: space-between;
}

.template {
  width: 99%;
  display: flex;
  gap: 0.25rem;
  padding: 0.15rem 0.25rem;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-bottom: 1.5px solid #f2f2f2;
}

.templateTitle {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 2px;
  cursor: pointer;
  font-size: 10px;
}

.templateBox {
  padding: 1rem;
  border: 1.5px solid #f2f2f2;
}

.buttonContainerSideBarBottom {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0 0.25rem;
  padding-top: 0.5rem;
}

.templateBoxEmail {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  font-size: 10px;
}

.emailTemplateSubject {
  border: 2px solid #f2f2f2;
  width: calc(100% - 0.3rem);
  text-align: left;
  padding: 0.25rem 0.15rem;
  border-radius: 5px;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 3px;
  font-weight: 500;
}

.emailTemplateSubject > span:nth-child(2) {
  padding-left: 2px;
  border-left: 2px solid #f2f2f2;
  padding-right: 0;
}

.emailTemplateMessage {
  background-color: #f2f2f2;
  width: calc(100% - 0.5rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1px;
  text-align: left;
  padding: 0.25rem;
  border-radius: 5px;
}

.emailTemplateMessage :nth-child(1) {
  width: 90%;
}

.emailTemplateMessage :nth-child(2) {
  width: 10%;
}

.fullTemplateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #bdbdbd;
  border-radius: 50%;
}

.fullTemplateButton:hover {
  cursor: pointer;
}

.tagDesign {
  background: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  font-weight: 550;
  position: relative;
  width: auto;
}

.addSynonym {
  position: absolute;
  padding: 0.5rem;
  background: #000;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  right: -10px;
  cursor: pointer;
}

.tagName {
  padding: 0 0.5rem;
}

.disselectTag {
  display: flex;
  text-align: center;
  height: 100%;
  padding-right: 5px;
  border-right: 2px solid #f2f2f2;
  cursor: pointer;
}

.synonymsTags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.synonymTagsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttonContainerSynonym {
  border-top: 2px solid #f2f2f2;
  padding-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.questionHeadingStep2 {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.buttonGroundStep2 {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem 4rem;
  gap: 1rem;
}

.questionnareBox_Container {
  display: flex;
  align-items: start;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 4rem 2rem;
}

.quessionareInputContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.quetionnaireBottomContainer {
  width: 100%;
  border-top: 2px solid #f2f2f2;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  gap: 1rem;
  flex-direction: row;
}

.questionnareBox {
  background-color: #fff;
  min-height: 300px;
  width: 80%;
  padding: 1rem;
}

.questionsButtonOption {
  font-size: 18px;
  color: #808080;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-right: 1.5px solid #bfbdbd;
  padding: 0 1rem;
}

.templateSectionQuessionare {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hideButton {
  font-weight: bold;
  cursor: pointer;
}

.templateButtonsContainer {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.templateOptionsContainers {
  width: 90%;
  padding: 1rem 5%;
  max-height: 350px;
  overflow-y: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.templateoptionsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.templateContainerBottom {
  border-bottom: 3px solid #f2f2f2;
}

.searchField {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  width: 97%;
  padding: 0.3rem;
  margin-bottom: 1rem;
  border: 1px solid #d1d1d1;
}

.searchtextField {
  height: 30px;
  width: 80%;
  background-color: #fff;
  border: 1px solid #fff;
}

.searchtextField:focus {
  background-color: #fff; /* Set the background color to transparent on focus */
  outline: none;
}

.searchIcon {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.questionsOptions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
}

.searchIcon:hover {
  color: #0778bd;
}

.templateheading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.templateBody {
  display: flex;
  padding: 1.5rem 1rem;
  width: calc(100% - calc(2rem + 2px));
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f2f2f2;
}

.noTemplates {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step1_1Container {
  display: flex;
  flex-direction: column;
}

.basicDetailsContainer {
  border: 2px dashed #f2f2f2;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.step1_1_field {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.fieldHeiading {
  font-size: 14px;
  color: #808080;
}

.experience_input {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.checkbox_step1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.locationTagsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  width: calc(100%- 2rem);
  padding: 0.15rem 0.5rem;
  border: 2px solid #f2f2f2;
}


@media screen and (max-width: 1300px){
  .templateContainer {
    height: calc(52.4vh);
    overflow-y: hidden;
    border-right: 1.5px solid #e2e2e2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}