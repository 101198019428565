.bannerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  /* Common styles for all screen sizes */
  .subBannercontainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3rem 1rem;
    padding-top: 5rem;
    gap: 1.25rem;
  }
  
  .bannerHeading {
    font-size: 48px; /* Default size */
    font-weight: 400;
    line-height: 1.15;
  }
  
  .bannerSubHeading {
    padding: 0 2rem;
    font-size: 20px; /* Default size */
    color: #302f2f;
  }
  
  .bannerButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding-top: 1rem;
  }
  
  .bannerButton1, .bannerButton2 {
    outline: none;
    border: none;
    padding: 1rem;
    /* width: 200px; */
    /* border: 1.5px solid #000; */
    /* border-radius: 5px; */
    font-size: 20px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    background: transparent;
    color: #191919;
    font-weight: 550;
  }
  
  /* .bannerButton1 {
    background: #f99c38;
  }
  
  .bannerButton2 {
    background: #f8e9d5;
  }
  
  .bannerButton1:hover {
    background: #ff8f18;
  }
  
  .bannerButton2:hover {
    background: #f5d09f;
  } */
  
  /* Tablet view (601px to 1024px) */
  @media screen and (min-width: 601px) and (max-width: 1024px) {
    .bannerHeading {
      font-size: 40px;
    }
  
    .bannerSubHeading {
      font-size: 18px;
      padding: 0 1.5rem;
    }
  
    .bannerButton1, .bannerButton2 {
      width: 180px;
      font-size: 15px;
    }
  }
  
  /* Desktop view (above 1024px) */
  @media screen and (min-width: 1025px) {
    .bannerHeading {
      font-size: 60px;
    }
  
    .bannerSubHeading {
      font-size: 22px;
      padding: 0 2rem;
    }
  
    .bannerButton1, .bannerButton2 {
      /* width: 200px; */
      font-size: 20px;
    }
  }
  
  
  
  /* Mobile view (up to 600px) */
  @media screen and (max-width: 600px) {
    .bannerHeading {
      font-size: 32px;
    }
  
    .bannerSubHeading {
      font-size: 16px;
      padding: 0 1rem;
    }
  
    .bannerButtons {
      flex-direction: column;
      gap: 1rem;
    }
  
    .bannerButton1, .bannerButton2 {
      width: 100%;
      font-size: 14px;
    }
  }