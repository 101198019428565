::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f3f3f3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #868686;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #383737;
}

.footerContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.nojobs {
  display: flex;
  align-items: center;
  height: 80%;
  justify-content: center;
  flex-direction: column;
}

.jobsContainer {
  height: calc(100vh);
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
  padding-top: 2.25rem;
  overflow-y: scroll;
}

.monkBotMenuContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 0.5rem 0.5rem 0.75rem 0.5rem;
}

.monkBotMenu {
  font-size: 20px;
  border-bottom: 2px solid#fff;
  font-weight: 500;
  text-align: center;
  width: 50px;
}

.monkBotMenuSelected {
  border-bottom: 2px solid #0778bd;
  color: #0778bd;
}

.monkBotMenu:hover {
  cursor: pointer;
}

.jobcardContainer {
  height: auto;
  width: calc(100%);
  display: flex;
  border-left: 5px solid transparent;
  padding: 0.5rem 0;
  flex-direction: column;
  border-bottom: 2px solid #f2f2f2;
  background-color: #fff;
  padding-left: 0.5rem;
}

.jobcardContainerSupportCard {
  height: auto;
  width: calc(100% - 2rem);
  display: flex;
  padding: 1rem;
  flex-direction: column;
  background-color: #fff;
  border-bottom: 2px solid #f2f2f2;
}

.jobcardContainer:hover {
  background-color: #f7fcff;
}

.selectedJobCard {
  height: auto;
  width: calc(100%);
  display: flex;
  border-left: 5px solid transparent;
  padding: 0.5rem 0;
  flex-direction: column;
  border-bottom: 2px solid #f2f2f2;
  background-color: #f7fcff;
  border-left: 4px solid #077ebd;
  padding-left: 0.5rem;
}

.jobCardDataContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.jobsCardOption {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 1rem);
  padding: 0.5rem 1rem;
}

.jobCardOptions {
  width: 20px;
}

.jobLastInfo {
  display: flex;
  width: 100%;
  margin-top: 1.5rem;
  border-top: 2px solid #f2f2f2;
}

.chatsContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  max-height: calc(100vh - 98px);
  overflow-y: scroll;
}

.filterContainerChat {
  padding: 1rem 0;
  width: 95%;
}

.searchField {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  width: 90%;
  padding: 0.5rem 1rem;
}

.searchtextField {
  height: 30px;
  width: 80%;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
}

.searchtextField:focus {
  background-color: #f2f2f2; /* Set the background color to transparent on focus */
  outline: none;
}

.searchIcon {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.searchIcon:hover {
  color: #0778bd;
}

.chatCardContainer {
  min-height: 85px;
  padding: 0 5%;
  width: calc(90% - 5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  border-left: 5px solid #fff;
}

.selectedChat {
  border-left: 5px solid #0778bd;
  background-color: #f7fcff;
}

.chatCardContainer:hover {
  background-color: #f7fcff;
}

.leftSideChatCard {
  display: flex;
  gap: 1rem;
}

.filterButtonContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  gap: 0.5rem;
}

.chatTopbar {
  background-color: #fff;
  padding: 0.5rem 5%;
  height: 50px;
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
}

.jobCardInfo {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.row {
  gap: 5px;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.rowHeading {
  margin-bottom: 0;
  padding-bottom: 0;
  font-weight: 500;
  font-size: 11px;
  color: #808080;
}

.rowSubHeading {
  font-weight: 650;
  font-size: 10px;
  color: #767676;
}

.companyTags {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  max-width: 80%;
  flex-wrap: wrap;
  gap: 4px 2px;
  font-size: 240px;
}

.locationRow {
  min-width: 10%;
}

.jobTags {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  max-width: 65%;
  flex-wrap: wrap;
  gap: 3px 1px;
}

.jobTagsJobSupport {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px 2px;
  max-width: 82%;
}

.tagsContainerJob {
  display: flex;
  flex-wrap: wrap;
}

.chatTopleftSide {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 1rem;
}

.chatMiddleInfo {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  text-align: center;
}

.dropDownFilter {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 0.5rem 1rem;
  width: 140px;
}

.dropDownIcon {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.dropDownIcon:hover {
  color: #0778bd;
}

.messageInputContainer {
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatSection {
  padding: 1rem;
  height: calc(100vh - calc(169px + 2rem));
  overflow-y: scroll;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1rem;
}

.userMessageContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.userMessage {
  max-width: 40%;
  border-radius: 5px;
  background: #e7ffdb;
  padding: 0.5rem 1rem;
}

.candidateMessageContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.candidiateMessage {
  border-radius: 5px;
  max-width: 40%;
  background: #fff;
  padding: 0.5rem 1rem;
}

/* Candidates for jobs styles */

.jobheadingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 0 1rem;
  gap: 1rem;
  height: 65px;
  border-bottom: 1.5px solid #f2f2f2;
}

.jobheadingSubContainer {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 2rem;
}

.jobheadingButton {
  font-size: 16px;
  padding: 0.5rem 1rem;
  border: 1px solid #0778bd;
  color: #0778bd;
}

.jobheadingFilters {
  padding: 0.05rem 0.5rem;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
}

.jobSubHeadingFilters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.candidatesUploadFilters {
  padding: 1rem 1.25rem;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #f2f2f2;
}

.candidatesUploadFilterName {
  color: #808080;
}

.jobCandidiateSectionContainer {
  width: 42vw;
  /* max-width: 101%; */
  min-height: 80vh;
  margin-top: 0.5rem;
  overflow: hidden;
}

.jobCandidiateHeadingFilterButtonSelected {
  padding: 0.25rem 0.75;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 3px 3px 0 0;
  background-color: transparent;
  border-bottom: 1.5px solid #808080;
}

.jobCandidiateHeadingFilterButtonNotSelected {
  padding: 0.25rem 0.75;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 3px 3px 0 0;
  background-color: transparent;
  border-bottom: 1.5px solid transparent;
}

.jobCandidatesSection {
  background-color: #f2f2f2;
  min-width: 100%;
  position: absolute;
  min-height: calc(100vh - calc(60px + 2.25rem));
  max-height: calc(100vh - calc(61px + 2.25rem));
  /* overflow-y: scroll; */
  overflow: hidden;
}

.noCandidatesCard {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 3rem 0;
  color: #616161;
  font-size: 1rem;
  font-weight: 600;
}

.noCandidatesCardLoad {
  display: flex;
  justify-content: center;
  height: 300px;
}

.jobCandidatesFilterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem;
  border-bottom: 1.5px solid #d9d9d9;
  background: #fff;
}

.jobCandidatesFilterLeft {
  display: flex;
  gap: 0.5rem;
}

.jobCandidatesFilterValue {
  font-weight: bold;
  font-size: 10px;
}

.jobCandidatesFilterButtonAll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.15rem 0.25rem;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  color: #808080;
  font-size: 10px;
}

.jobCandidatesFilterButtonAllSelected {
  background-color: #efefef;
  color: #000;
}

.jobCandidatesFilterButtonAllRank {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.35rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #808080;
  font-size: 10px;
  background-color: #f2f2f2;
}

.jobCandidatesFilterButtonAllSelectedRank {
  color: #000;
  background-color: #fff;
}

.rankFilterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.jobCandaidateFilterContact {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.jobCandaidateFilterContactButton {
  padding: 0.75rem 0.5rem;
  font-weight: 450;
  font-size: 11px;
  background-color: #fff;
  cursor: pointer;
  border: none;
}

.jobCandaidateFilterContactButtonMobile {
  border-radius: 5px 0 0 5px;
}

.jobCandaidateFilterContactButtonSelected {
  background-color: #0778bd;
  color: #fff;
}

.jobCandaidateFilterContactButtonEmail {
  border-radius: 0 5px 5px 0;
}

.candidatesCards {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 0.5rem;
}

.candidatesCard {
  padding: 0.5rem 1rem;
  min-height: 200px;
  width: calc(100% - 1rem);
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 1.5px solid #e6e6e6;
}

.selectedCandidateBackground {
  background-color: #f7fcff;
}

.candidateName {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.candidateCardHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.candidateCardTitle {
  cursor: pointer;
  color: #0778bd;
  text-decoration: underline;
  font-size: 11px;
  font-weight: 500;
}

.candidateCardSubHeading {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.5rem 4px 4px 1rem;
  gap: 2.5rem;
  font-size: 10px;
  font-weight: 500;
}

.candidateCardIcon {
  width: 18px;
}

.candidateCardBasicInfoHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.candidateCardBasicInfoTitle {
  font-size: 10px;
}

.candidateRow {
  display: flex;
  align-items: start;
  justify-content: start;
  padding: 0.25rem 1rem;
  width: calc(100%);
}

.candidateRowHeading {
  width: 25%;
  font-weight: 400;
  font-size: 2px;
}

.candidateRowDetails {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 10px;
  font-weight: 500;
}

.candidateJobExperienceData {
  display: flex;
  align-items: start;
  gap: 1rem;
}

.candidateIndustryType {
  display: inline;
  max-width: 75%;
  font-size: px;
}

.candidateCompanyName {
  display: flex;
}

.candidateJobExperienceCompany {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 20%;
  max-width: 30%;
}

.candidateCompanyType {
  background-color: #e6e6e6;
  color: #5d5d5d;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  padding: 2px 4px;
  font-size: 8px;
  border-radius: 5px;
}

.booleanAnimationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  gap: 1rem;
  position: relative;
  height: 30vh;
}

.booleanAnimationContainer > div {
  width: 70%;
}

.animatedText {
  position: absolute;
  width: 70%;
  top: 80%;
}

.candtidatesMessageAction {
  border-top: 1.5px solid #e6e6e6;
  display: flex;
  padding: 0.5rem 0.5rem 0rem;
  font-size: 10px;
  align-items: center;
  justify-content: space-between;
}

.candtidatesMessageActionAllShow {
  width: 100%;
  padding: 1rem 1rem 0.25rem 1.15rem;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  color: #0778bd;
}

.modalBg {
  height: 100%;
  width: 100%;
  background: #00000013;
}

.modal {
  position: absolute;
  top: 40%;
  left: 30%;
  background: #fff;
  padding: 0.5rem;
  border-radius: 5px;
  width: 50%;
  z-index: 99;
  padding-top: 2rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.432); /* Add this for box shadow */
}
