.moreInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #0778bd;
  align-items: center;
  justify-content: start;
  min-height: 90vh;
  padding: 2rem;
  position: relative;
}

.moreInfoSubContainer {
  position: relative;
  z-index: 1; /* Keep it on top */
  text-align: center;
  display: flex;
  width: 80%;
  flex-direction: column;
  /* padding: 0 4rem; */
  text-align: left;
}

.moreInfoHeading {
  font-size: 40px;
  line-height: 1.25;
  text-align: left;
  font-weight: 550;
  color: #ffffff;
  margin-bottom: 0rem;
  width: 80%;
}

.moreInfoSubHeading {
  font-size: 18px;
  font-weight: 400;
  color: #e3f2fd;
  margin-bottom: 2rem;
}

/* Button styles */
.moreInfoButton {
  outline: none;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 16px;
  background-color: #f2f2f2;
  color: #212121;
  width: 200px;
  border-radius: 30px;
  cursor: pointer;
}

.buttonCommonStyle {
  position: absolute;
  border: none;
  padding: 0.5rem 1.25rem;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-radius: 30px;
  background-color: #828282;
  cursor: grab;
}

.buttonCommonStyle {
  background-color: #0778BD;
  border: 1.5px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0.5rem 1.25rem;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-radius: 30px;
  cursor: grab;
  position: absolute;
}

/* Keyframes for animations */
@keyframes raindrop {
  0% {
    transform: translateY(-100vh) rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotate(var(--rotate));
    opacity: 1;
  }
}

/* Base animation styles */
.moreInfoFloatingButton {
  animation: raindrop var(--duration) ease-in-out forwards;
}

/* Button-specific variables */
.moreInfoFloatingButton1 {
  --duration: 1.25s;
  --rotate: 21deg;
  bottom: 30px;
  left: calc(40% + 170px);
  width: 220px;
}

.moreInfoFloatingButton2 {
  --duration: 0.95s;
  --rotate: 0deg;
  bottom: 0%;
  left: 40%;
  width: 250px;
}

.moreInfoFloatingButton3 {
  --duration: 1.25s;
  --rotate: -11deg;
  bottom: 18px;
  left: calc(15% + 170px);
  width: 220px;
}

/* Repeat similar structures for other buttons */
.moreInfoFloatingButton4 {
  --duration: 1.35s;
  --rotate: 78deg;
  bottom: 85px;
  left: calc(47.5% + 170px);
  width: 220px;
}

.moreInfoFloatingButton5 {
  --duration: 1.45s;
  --rotate: -28deg;
  bottom: 85px;
  left: calc(30.5% + 170px);
  width: 220px;
}

.moreInfoFloatingButton6 {
  --duration: 1.75s;
  --rotate: 28deg;
  bottom: 43px;
  left: calc(56% + 170px);
  width: 220px;
}

.moreInfoFloatingButton7 {
  --duration: 1.45s;
  --rotate: -26.5deg;
  bottom: 38px;
  left: calc(7% + 170px);
  width: 220px;
}

.moreInfoFloatingButton8 {
  --duration: 1.65s;
  --rotate: 20deg;
  bottom: 118px;
  left: calc(10.5% + 170px);
  width: 320px;
}

.moreInfoFloatingButton9 {
  --duration: 1.9s;
  --rotate: 5deg;
  bottom: 160px;
  left: calc(19% + 170px);
  width: 320px;
}

.moreInfoFloatingButton10 {
  --duration: 2.1s;
  --rotate: -60deg;
  bottom: 120px;
  left: calc(-3.5% + 170px);
  width: 320px;
}

.moreInfoFloatingButton11 {
  --duration: 2.1s;
  --rotate: -52deg;
  bottom: 175px;
  left: calc(41.2% + 170px);
  width: 260px;
}

.moreInfoFloatingButton12 {
  --duration: 2.25s;
  --rotate: -11deg;
  bottom: 220px;
  left: calc(33.5% + 170px);
  width: 260px;
}

.moreInfoFloatingButton13 {
  --duration: 1.85s;
  --rotate: 26deg;
  bottom: 50px;
  right: calc(-2% + 170px);
  width: 260px;
}

.moreInfoFloatingButton14 {
  --duration: 2.45s;
  --rotate: -44deg;
  bottom: 270px;
  left: calc(11% + 170px);
  width: 240px;
}

.moreInfoFloatingButton15 {
  --duration: 2.25s;
  --rotate: 5deg;
  bottom: 212px;
  left: calc(18.7% + 170px);
  width: 220px;
}

.buttonCommonStyle {
  background-color: #0778BD;
  border: 1.5px solid #fff;
  display: flex;
  align-items: center;
  justify-content:center;
}


@media screen and (min-width: 1920px) {

  .moreInfoContainer {
    min-height: 80vh;
  }

  .moreInfoFloatingButton1 {
    --duration: 1.25s;
    --rotate: 21deg;
    bottom: 30px;
    left: calc(40% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton2 {
    --duration: 0.95s;
    --rotate: 0deg;
    bottom: 0%;
    left: 40%;
    width: 250px;
  }
  
  .moreInfoFloatingButton3 {
    --duration: 1.25s;
    --rotate: -18deg;
    bottom: 28px;
    left: calc(23% + 170px);
    width: 220px;
  }
  
  /* Repeat similar structures for other buttons */
  .moreInfoFloatingButton4 {
    --duration: 1.35s;
    --rotate: 78deg;
    bottom: 85px;
    left: calc(46% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton5 {
    --duration: 1.45s;
    --rotate: -28deg;
    bottom: 85px;
    left: calc(33% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton6 {
    --duration: 1.75s;
    --rotate: 28deg;
    bottom: 43px;
    left: calc(52.5% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton7 {
    --duration: 1.45s;
    --rotate: -26.5deg;
    bottom: 38px;
    left: calc(17.5% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton8 {
    --duration: 1.65s;
    --rotate: 18deg;
    bottom: 123px;
    left: calc(18.5% + 170px);
    width: 320px;
  }
  
  .moreInfoFloatingButton9 {
    --duration: 1.9s;
    --rotate: 5deg;
    bottom: 163px;
    left: calc(25% + 170px);
    width: 320px;
  }
  
  .moreInfoFloatingButton10 {
    --duration: 2.1s;
    --rotate: -20deg;
    bottom: 50px;
    left: calc(9% + 170px);
    width: 320px;
  }
  
  .moreInfoFloatingButton11 {
    --duration: 2.1s;
    --rotate: -50deg;
    bottom: 170px;
    left: calc(41.2% + 170px);
    width: 260px;
  }
  
  .moreInfoFloatingButton12 {
    --duration: 2.25s;
    --rotate: 5deg;
    bottom: 198px;
    left: calc(33.2% + 170px);
    width: 260px;
  }
  
  .moreInfoFloatingButton13 {
    --duration: 1.85s;
    --rotate: 26deg;
    bottom: 50px;
    right: calc(12.5% + 170px);
    width: 260px;
  }
  
  .moreInfoFloatingButton14 {
    --duration: 2.45s;
    --rotate: -50deg;
    bottom: 140px;
    left: calc(10% + 170px);
    width: 240px;
  }
  
  .moreInfoFloatingButton15 {
    --duration: 2.25s;
    --rotate: 14deg;
    bottom: 240px;
    left: calc(18.7% + 170px);
    width: 220px;
  }
}


@media screen and (min-width: 1441px) and (max-width: 1919px) {
  .moreInfoFloatingButton1 {
    --duration: 1.25s;
    --rotate: 21deg;
    bottom: 30px;
    left: calc(40% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton2 {
    --duration: 0.95s;
    --rotate: 0deg;
    bottom: 0%;
    left: 40%;
    width: 250px;
  }
  
  .moreInfoFloatingButton3 {
    --duration: 1.25s;
    --rotate: -16deg;
    bottom: 23px;
    left: calc(20% + 170px);
    width: 220px;
  }
  
  /* Repeat similar structures for other buttons */
  .moreInfoFloatingButton4 {
    --duration: 1.35s;
    --rotate: 78deg;
    bottom: 85px;
    left: calc(46.5% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton5 {
    --duration: 1.45s;
    --rotate: -26deg;
    bottom: 82px;
    left: calc(32.5% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton6 {
    --duration: 1.75s;
    --rotate: 28deg;
    bottom: 43px;
    left: calc(53.8% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton7 {
    --duration: 1.45s;
    --rotate: -18.5deg;
    bottom: 28px;
    left: calc(11.5% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton8 {
    --duration: 1.65s;
    --rotate: 20deg;
    bottom: 122px;
    left: calc(10.5% + 170px);
    width: 320px;
  }
  
  .moreInfoFloatingButton9 {
    --duration: 1.9s;
    --rotate: 5deg;
    bottom: 142px;
    left: calc(21% + 170px);
    width: 320px;
  }
  
  .moreInfoFloatingButton10 {
    --duration: 2.1s;
    --rotate: -60deg;
    bottom: 120px;
    left: calc(-1.5% + 170px);
    width: 320px;
  }
  
  .moreInfoFloatingButton11 {
    --duration: 2.1s;
    --rotate: -52deg;
    bottom: 175px;
    left: calc(41.2% + 170px);
    width: 260px;
  }
  
  .moreInfoFloatingButton12 {
    --duration: 2.25s;
    --rotate: -11deg;
    bottom: 200px;
    left: calc(33.5% + 170px);
    width: 260px;
  }
  
  .moreInfoFloatingButton13 {
    --duration: 1.85s;
    --rotate: 26deg;
    bottom: 50px;
    right: calc(7% + 170px);
    width: 260px;
  }
  
  .moreInfoFloatingButton14 {
    --duration: 2.45s;
    --rotate: -20deg;
    bottom: 235px;
    left: calc(13% + 170px);
    width: 240px;
  }
  
  .moreInfoFloatingButton15 {
    --duration: 2.25s;
    --rotate: 5deg;
    bottom: 192px;
    left: calc(20.7% + 170px);
    width: 220px;
  }
}


@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .moreInfoContainer {
    min-height: 98vh;
    padding:1rem 2rem;
  }

  .moreInfoHeading {
    font-size: 30px;
  }
  
  .moreInfoSubHeading {
    font-size: 14px;
  }

  .buttonCommonStyle {
    padding: 0.5rem .75rem;
    font-size: 14px;
  }

  .moreInfoFloatingButton1 {
    --duration: 1.25s;
    --rotate: 21deg;
    bottom: 30px;
    left: calc(40% + 170px);
    width: 200px;
  }
  
  .moreInfoFloatingButton2 {
    --duration: 0.95s;
    --rotate: 0deg;
    bottom: 0%;
    left: 40%;
    width: 250px;
  }
  
  .moreInfoFloatingButton3 {
    --duration: 1.25s;
    --rotate: -16deg;
    bottom: 23px;
    left: calc(15% + 170px);
    width: 200px;
  }
  
  .moreInfoFloatingButton4 {
    --duration: 1.35s;
    --rotate: 78deg;
    bottom: 85px;
    left: calc(46.5% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton5 {
    --duration: 1.45s;
    --rotate: -26deg;
    bottom: 82px;
    left: calc(30.5% + 170px);
    width: 200px;
  }
  
  .moreInfoFloatingButton6 {
    --duration: 1.75s;
    --rotate: 80deg;
    bottom: 120px;
    left: calc(49.8% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton7 {
    --duration: 1.45s;
    --rotate: -27.5deg;
    bottom: 38px;
    left: calc(6% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton8 {
    --duration: 1.65s;
    --rotate: 20deg;
    bottom: 122px;
    left: calc(9.5% + 170px);
    width: 300px;
  }
  
  .moreInfoFloatingButton9 {
    --duration: 1.9s;
    --rotate: 1deg;
    bottom: 162px;
    left: calc(21% + 170px);
    width: 300px;
  }
  
  .moreInfoFloatingButton10 {
    --duration: 1.75s;
    --rotate: -35deg;
    bottom: 60px;
    left: calc(-5.5% + 170px);
    width: 270px;
  }
  
  .moreInfoFloatingButton11 {
    --duration: 2.1s;
    --rotate: -52deg;
    bottom: 175px;
    left: calc(41.2% + 170px);
    width: 260px;
  }
  
  .moreInfoFloatingButton12 {
    --duration: 2.25s;
    --rotate: -16deg;
    bottom: 226px;
    left: calc(36.5% + 170px);
    width: 220px;
  }
  
  .moreInfoFloatingButton13 {
    --duration: 1.85s;
    --rotate: 0deg;
    bottom: 00px;
    right: calc(-4.5% + 170px);
    width: 260px;
  }
  
  .moreInfoFloatingButton14 {
    --duration: 2.45s;
    --rotate: -27deg;
    bottom: 235px;
    left: calc(12% + 170px);
    width: 190px;
  }
  
  .moreInfoFloatingButton15 {
    --duration: 2.25s;
    --rotate: 2deg;
    bottom: 207px;
    left: calc(20.7% + 170px);
    width: 180px;
  }

}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  
  .moreInfoFloatingButton13 {
    --duration: 1.85s;
    --rotate: 26deg;
    bottom: 50px;
    right: calc(4% + 170px);
    width: 260px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  /* Base animation styles */
.moreInfoFloatingButton {
  animation: raindrop var(--duration) ease-in-out forwards;
}

/* Button-specific variables */
.moreInfoFloatingButton1 {
  --duration: 1.25s;
  --rotate: 21deg;
  bottom: 30px;
  left: calc(40% + 170px);
  width: 220px;
}

.moreInfoFloatingButton2 {
  --duration: 0.95s;
  --rotate: 0deg;
  bottom: 0%;
  left: 40%;
  width: 250px;
}

.moreInfoFloatingButton3 {
  --duration: 1.25s;
  --rotate: -11deg;
  bottom: 18px;
  left: calc(15% + 170px);
  width: 220px;
}

/* Repeat similar structures for other buttons */
.moreInfoFloatingButton4 {
  --duration: 1.35s;
  --rotate: 78deg;
  bottom: 85px;
  left: calc(47.5% + 170px);
  width: 220px;
}

.moreInfoFloatingButton5 {
  --duration: 1.45s;
  --rotate: -28deg;
  bottom: 85px;
  left: calc(30.5% + 170px);
  width: 220px;
}

.moreInfoFloatingButton6 {
  --duration: 1.75s;
  --rotate: 28deg;
  bottom: 43px;
  left: calc(56% + 170px);
  width: 220px;
}

.moreInfoFloatingButton7 {
  --duration: 1.45s;
  --rotate: -26.5deg;
  bottom: 38px;
  left: calc(7% + 170px);
  width: 220px;
}

.moreInfoFloatingButton8 {
  --duration: 1.65s;
  --rotate: 20deg;
  bottom: 118px;
  left: calc(10.5% + 170px);
  width: 320px;
}

.moreInfoFloatingButton9 {
  --duration: 1.9s;
  --rotate: 5deg;
  bottom: 160px;
  left: calc(19% + 170px);
  width: 320px;
}

.moreInfoFloatingButton10 {
  --duration: 2.1s;
  --rotate: -60deg;
  bottom: 120px;
  left: calc(-3.5% + 170px);
  width: 320px;
}

.moreInfoFloatingButton11 {
  --duration: 2.1s;
  --rotate: -52deg;
  bottom: 175px;
  left: calc(41.2% + 170px);
  width: 260px;
}

.moreInfoFloatingButton12 {
  --duration: 2.25s;
  --rotate: -11deg;
  bottom: 220px;
  left: calc(33.5% + 170px);
  width: 260px;
}

.moreInfoFloatingButton13 {
  --duration: 1.85s;
  --rotate: 26deg;
  bottom: 50px;
  right: calc(-2% + 170px);
  width: 260px;
}

.moreInfoFloatingButton14 {
  --duration: 2.45s;
  --rotate: -44deg;
  bottom: 270px;
  left: calc(11% + 170px);
  width: 240px;
}

.moreInfoFloatingButton15 {
  --duration: 2.25s;
  --rotate: 5deg;
  bottom: 212px;
  left: calc(18.7% + 170px);
  width: 220px;
}
}