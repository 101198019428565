.footerContainer {
  padding: 5rem 2rem 3rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.footerMainContainer{
    background: #F5FBFF;
    width: 60%;
    padding: 1rem 2rem;
    border: 1px solid #191919;
border-radius: 2px;
display: flex;
gap: 1rem;
align-items: center;
justify-content: center;
flex-direction: column;
}

.footerHeading{
    font-size: 28px;
    color: #111111;
}