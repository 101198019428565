.tableContainer{
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100vw;
  height: 100vh;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
  }
  
  .form {
    width: 300px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /* gap: 1.5rem; */
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .inputGroup {
    margin-bottom: 1rem;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #666;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
  }
  
  .button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background-color: #f99c38;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #f07c1c; /* Add a hover effect for better UX */
  }
  