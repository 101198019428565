.helpMainContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  position: relative;
}

.helpSubContainer {
  width: 100%;
  border: 1.5px solid #000;
  background: #f8e9d5;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.helpContainerInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  justify-content: start;
  width: 28%;
}

.helpHeading {
  font-size: 49px;
  font-weight: 400;
}

.helpSubHeading > span {
  text-decoration: underline;
}

.helpSubHeading {
  font-size: 22px;
  color: #5d5d5d;
}

.helpPointsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 1.5rem;
}

.helpPointContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
}

.helpPoint {
  font-weight: 500;
  font-size: 20px;
}

.helpAmount {
  transform: rotate(-5deg);
  position: relative;
}

.helpAmount > span:nth-child(1) {
  font-size: 300px;
}

.helpAmount > span:nth-child(2) {
  font-size: 20px;
  position: absolute;
  width: 100%;
  top: 78%;
  right: -55%;
  font-weight: 500;
}

.arrowImg {
  position: absolute;
  top: -20%;
  right: -25%;
  width: 110%;
}

@media screen and (max-width: 768px) {
  .helpAmountContainer {
    display: none;
  }

  .helpContainerInfo {
    width: 100%;
    padding: 1rem 1.15rem;
  }

  .helpHeading {
    font-size: 34px;
  }

  .helpSubHeading {
    font-size: 18px;
  }

  .helpPoint {
    font-weight: 500;
    font-size: 18px;
  }
}
