.testimonialsContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #313131;
    padding-bottom: 4rem;
}

.testimonialsSubContainer{
    display: flex;
    gap: 1rem;
    width: 100%;
    overflow: scroll; /* or 'auto' if you want it to show only when needed */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding: 0.75rem;
}

.testimonialCard{
    background: #FBFAF8;
    border: 1.25px solid #000;
    border-radius: 5px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    min-width: 499px;
    max-width: 500px;
}

.imgContainer{
    background: #000;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.imgContainer > img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.testimonialHeader{
    display: flex;
    align-items: start;
    justify-content: flex-start;
    gap: 1.5rem;
    color: #191919;
}

.name{
    font-size:20px;
    font-weight: 500;
}

.position, .company{
    font-size:16px;
    font-weight: 400;
}

.message{
    font-weight: 400;
    font-size:16px;
}

.testimonialsSubContainer::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}


@media screen and (max-width: 576px) {
    .testimonialCard{
        min-width: 299px;
        max-width: 300px;
    }
}