
@keyframes shine {
  0% {
    background-position: 110% 0%, 0 0;
  }
  100% {
    background-position: -10% 0%, 0 0;
  }
}

.ptext {
  position: relative;
  display: inline-block;
  /* font-family: "Source Sans Pro", sans-serif; */
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 10px;
  text-transform: uppercase;
  margin: 0;
}
[data-gold] {
  color: #ac733c;
}
@supports ((background-clip: text) or (-webkit-background-clip: text)) {
  [data-gold] {
    color: transparent;
    background-image: linear-gradient(
        to right,
        transparent 0%,
        transparent 45%,
        white 50%,
        transparent 55%,
        transparent 100%
      ),
      linear-gradient(
        90deg,
        #c78c48,
        #cf9147 9.4%,
        #cf9348 9.4%,
        #b2763e 33.6%,
        #ad743c 35.7%,
        #ac723d 46.9%,
        #b0773d 51.7%,
        #b0793e 52.1%,
        #c4914c 64.6%,
        #c99750 68.5%,
        #ce9e54 73.2%,
        #f4d188
      );
    background-position: 110% 0%, 0 0;
    background-size: 200% auto, auto;
    -webkit-background-clip: text;
    background-clip: text;
    animation: shine 3s ease-in-out 4 alternate 2s;
  }
}
[data-gold]:before {
  content: attr(data-gold);
  color: #f0d8a9;
  position: absolute;
  z-index: -1;
  top: -0.08vw;
  left: 0px;
  /* text-shadow: black 0px 0.08vw 12px; */
}
@supports ((background-clip: text) or (-webkit-background-clip: text)) {
  [data-gold]:after {
    content: attr(data-gold);
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 48%,
      rgba(98, 16, 0, 0.5) 50%,
      transparent 75%
    );
    -webkit-background-clip: text;
    background-clip: text;
  }
}
[data-silver] {
  color: #858585;
}
@supports ((background-clip: text) or (-webkit-background-clip: text)) {
  [data-silver] {
    color: transparent;
    background-image: linear-gradient(
        to right,
        transparent 0%,
        transparent 45%,
        white 50%,
        transparent 55%,
        transparent 100%
      ),
      linear-gradient(
        270deg,
        #8c8c8c 1.3%,
        #999 15%,
        #868686 29.6%,
        #828282 29.6%,
        #7d7d7d 31.8%,
        #797979 31.8%,
        #6a6a6a 38.9%,
        #d3d3d3
      );
    background-position: 110% 0%, 0 0;
    background-size: 200% auto, auto;
    -webkit-background-clip: text;
    background-clip: text;
    animation: shine 3s ease-in-out 4 alternate-reverse 2s;
  }
}
[data-silver]:before {
  content: attr(data-silver);
  color: #fff;
  position: absolute;
  z-index: -1;
  top: -0.08vw;
  left: 0px;
  /* text-shadow: black 0px 0.08vw 12px; */
}
@supports ((background-clip: text) or (-webkit-background-clip: text)) {
  [data-silver]:after {
    content: attr(data-silver);
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 48%,
      rgba(17, 17, 17, 0.5) 50%,
      transparent 75%
    );
    -webkit-background-clip: text;
    background-clip: text;
  }
}


.appinfo-body{
  background-color: #F8F9FA;
  margin:0px auto;
  padding: 10px 0px; 
  display: flex;
  justify-content: center;
}

.appinfo-body .appinfo-main{
  width:65%;
  background-color:#FFF;
  padding:30px;
}


@media (max-width:768px) {
  .appinfo-body .appinfo-main{
      width:85%;
      padding: 30px 20px;
  }        
}



@media (max-width:768px) {
  .appinfo-body .appinfo-main{
      width:97.5%;
      padding: 20px 10px;
  }        
}
