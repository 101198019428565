.subFooterContainer{
    display: flex;
    /* width: 80%; */
    align-items: center;
    justify-content:center;
    padding: 4rem 1rem 2rem 1rem;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 5rem;
}

.footerHeading{
    font-size:40px;
    font-weight: 400;
}

.subFooterCardsContainer{
    display: flex;
    align-items: center;
    justify-content:center;
    gap: 2rem;
}

.subFooterCardContainer{
    width: 400px;
    border: 1px solid #000;
    background: #F8E9D5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    border-radius: 10px;
    padding: 1rem 2rem;
    gap: 1rem;
}

.cardHeading{
    font-size:30px;
    font-weight: 400;
    color: #111111;
}

.cardDesc{
    font-size:18px;
    text-align: center;
    color: #191919;
}