.bannerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Common styles for all screen sizes */
.subBannercontainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3rem 1rem;
  padding-top: 3rem;
  gap: 1.25rem;
}

.qhbannerHeading {
  font-size: 64px;
  line-height: 1.05;
  font-weight: 400;
  position: relative;
}

.strokeImg {
  position: absolute;
  bottom: -1rem;
  right: 1rem;
  color: #191919;
}

.bannerSubHeading {
  padding: 0 2rem;
  font-size: 10px;
  color: #5d5d5d;
}

.bannerButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding-top: 1.5rem;
}

.bannerButton1,
.bannerButton2 {
  outline: none;
  border: none;
  padding: 0.75rem 1rem;
  min-width: 250px;
  border: 1.5px solid #000;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.bannerButton1 {
  background: #f99c38;
}

.bannerButton1:hover {
  background: #ff8f18;
}

.stepsContainer {
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.stepHeading {
  font-size: 40px;
  /* width: 70%; */
  font-weight: 500;
  line-height: 1.15;
}

.stepSubHeading {
  padding-top: 1.5rem;
}

.StepsImageContainer {
  width: 100vw;
  overflow-x: scroll;
  height: 105vh;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding-top: 5vh;
}

.StepsImageContainer::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.stepsImgContainer {
  position: relative;
  width: 550vw;
  height: 40vh;
}

.stepImg {
  width: 400px; /* Set the width to 400px */
  height: auto; /* Auto height to maintain aspect ratio */
  object-fit: contain; /* Ensure image is scaled properly within the container */
  scroll-snap-align: start; /* Snap images to the start when scrolling */
}

.content1, .content2, .content3{
  width: 200px;
  font-size: 22px;
  position: absolute;
  z-index: 100;
}

.content1 {
  top: 85%;
  left: 11.7%;
}

.content2 {
  top: 95%;
  left: calc(330px + 11.7%);
}

.content3 {
  top: 105%;
  left: calc(660px + 11.7%);
}

.stepImg1,
.stepImg2,
.stepImg3 {
  position: absolute;
  top: 50%;
}

.stepImg1 {
  left: 10.5%;
  top: 21%;
}

.stepImg2 {
  left: calc(360px + 10%);
}

.stepImg3 {
  left: calc(690px + 10%);
}

/* Tablet view (601px to 1024px) */
  /* @media screen and (min-width: 601px) and (max-width: 1024px) {
    .qhbannerHeading {
      font-size: 48px;
    }

    .bannerSubHeading {
      font-size: 18px;
      padding: 0 1.5rem;
    }

    .bannerButton1,
    .bannerButton2 {
      width: 180px;
      font-size: 15px;
    }
  } */

/* Mobile view (up to 600px) */
/* @media screen and (min-width: 600px) {
  .qhbannerHeading {
    font-size: 32px;
  }

  .bannerSubHeading {
    font-size: 16px;
    padding: 0 1rem;
  }

  .bannerButtons {
    flex-direction: column;
    gap: 1rem;
  }

  .bannerButton1,
  .bannerButton2 {
    width: 100%;
    font-size: 14px;
  }
} */

@media screen and (min-width: 1300px) {
  .qhbannerHeading {
    font-size: 64px;
  }

  .bannerSubHeading {
    font-size: 22px;
    padding: 0 2rem;
  }

  .stepImg1 {
    left: 10.4%;
    top: 22%;
  }

  .content1, .content2, .content3{
    font-size: 24px;
  }

  .content1 {
    top: 75%;
    left: 11.4%;
  }
  
  .content2 {
    top: 85%;
    left: calc(330px + 11.4%);
  }
  
  .content3 {
    top: 95%;
    left: calc(660px + 11.3%);
  }

  .bannerButton1,
  .bannerButton2 {
    width: 200px;
    font-size: 16px;
  }
}
