.mainSourcingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.topBar {
  display: flex;
  background: #ffffff47;
  border-radius: 10px;
  width: 75%;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
}

.designationBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.designationBarOptions {
  display: flex;
  justify-content: start;
  width: 85%;
}

.filterBar {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropDownContainer {
  display: flex;
  width: 80%;
  gap: 0.5rem;
  color: #ffffff80;
}

.countContainer {
  display: flex;
  align-items: center;
  /* flex-direction: column-reverse; */
  justify-content: space-between;
  gap: 0.25rem;
  cursor: pointer;
  font-weight: 500;
  padding: 5px;
  border-radius: 4px;
}

.sourcingContainer {
  background: linear-gradient(135deg, #fff, #ffffff);
  width: 75%;
  padding: 0;
  border-radius: 10px;
}

.candidateType {
  text-align: center;
  cursor: pointer;
  width: 240px;
}

.candidateType h3 {
  margin: 0;
  font-weight: 500;
}

.candidateType span {
  font-size: 0.8rem;
  color: #777;
}

.filterBar select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 48%;
}

.candidateList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  background: #fff;
  border-radius: 30px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards; /* Adjust duration and easing as needed */
}


.candidateCard {
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0.75rem 1rem 0.75rem;
  background: #fff;
  border-bottom: 1px solid #E4E4E2;
}

.selectDesigMessage{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content:center;
  font-size:18px;
  color: #787878;
  padding: 2rem;
}

.profileInfo {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  min-width: 79.9%;
  max-width: 80%;
}

.profilePicture {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  margin-right: 25px;
}

.noCandidatePic{
  width: 58px;
  height: 58px;
  border-radius: 50%;
  margin-right: 25px;
  background: #f2f2f2;
}

.candidateInfo{
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 75%;
}

.candidateName {
    font-size: 17px;
    font-weight: 550;
    color: #1d1d1d;
}

.candidateRole {
    font-size: 15px;
    font-weight: 400;
    color: #303030;
}

.candidateLocation{
    font-size: 15px;
    color: #787878;
}

.candidatePastRole{
    font-size:13px;
    color: #787878;
}

.candidateConections{
    font-size:13px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #787878;
}

.connectButton {
  padding: 10px 20px;
  color: #007bff;
  background-color: white;
  /* border: none; */
  border: 1px solid #007bff;
  border-radius: 30px;
  cursor: pointer;
  transition: ease-in-out 0.4s;
}

.connectButton:hover {
  background-color: #007bff;
  color: #fff;
}

.loginMessage{
    font-size:40px;
    font-weight: 400;
}

.loginOption{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    width: 75%;
    padding: 1rem 1rem 4rem 1rem;
}

.skeletonLoader{
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0.75rem 1rem 0.75rem;
  background: #fff;
  border-bottom: 1px solid #E4E4E2;
}

.skeletonInfo{
  display: flex;
  width: 80%;
  gap: 2rem;
}

.infoConainerSkeleton{
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 0.5rem;
}

.posotionSkeleton{
  display: flex;
  gap: 0.75rem;
}

.paginationContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paginationButton {
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.paginationButton:hover:not(.activePage) {
  background-color: #f0f0f0;
}

.paginationButtonDisabled {
  background-color: #f9f9f9;
  color: #aaa;
  cursor: not-allowed;
}

.paginationButtons{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/* Active Page */
.activePage {
  background-color: #f99c38;
  color: #000;
  font-weight: bold;
  cursor: default;
}

/* YourStyles.module.css */
.countContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.skeletonLoaderIsViewed {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  padding: 8px;
}

.skeletonIcon {
  width: 24px;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 50%;
  animation: skeleton-loading 1.5s infinite;
}

.skeletonText {
  width: 16px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.refferalCodeText{
  display: flex;
  align-items: center;
  justify-content: start;
  width: 65%;
  color: #787878;
  font-size: 16px;
}

.refferalCodeText > span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-weight: 500;
  cursor: pointer;
  color: #191919;
}

.blurContainer {
  position: relative;
  overflow: hidden;
  width: 95%;
}

.candidatesList {
  filter: blur(5px); 
  pointer-events: none; 
  opacity: 0.5; 
}

.overlayContainer{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; 
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.overlayButton {
  background-color: #f99c38;
  border:1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  width: 80%;
  font-size: 18px;
  font-weight: 500;
}

.blurCandidateCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0.75rem 1rem 0.75rem;
  background: #fff;
  border-bottom: 1px solid #E4E4E2;
}

.candidateCard {
  position: relative;
}

.profileInfo,
.connectButton {
  z-index: 0; 
}

@keyframes zoomInFocus {
  0% {
    transform: scale(1); /* Original size */
    opacity: 0.7; /* Slightly transparent */
  }
  50% {
    transform: scale(1.1); /* Smooth zoom-in */
    opacity: 1; /* Fully visible */
  }
  100% {
    transform: scale(1); /* Back to original size */
    opacity: 0.7; /* Slightly transparent */
  }
}

.viewMoreAnim {
  animation: zoomInFocus 2s ease-in-out infinite; /* Continuous smooth animation */
  transform-origin: center; /* Ensure zoom happens from center */
}

.viewMoreAnim:hover {
  animation: none;
}

