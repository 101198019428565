.tc_container {
    width: 100%;
    padding: 2rem 2rem 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .tc_subcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    padding: 2rem 1rem;
    background: #f8e9d5;
    border-radius: 6px;
    border: 1.5px solid #000;
    gap: 0.5rem;
  }
  
  .tc_heading {
    font-size: 30px;
    font-weight: 400;
  }
  
  .tc_points {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0;
  }
  
  .tc_subpoints {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .tc_mark {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000;
  }
  
  .tc_point_content {
    font-size: 16px;
    font-weight: 450;
  }
  
  .join_diploma {
    padding: 0.75rem 1rem;
    text-align: center;
    background-color: #f99c38;
    color: #191919;
    font-weight: 500;
    font-size: 17px;
    width: 100%;
    border-radius: 5px;
    border: 1.5px solid #000;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .join_diploma:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.692);
  }
  
  
  
  @media screen and (max-width: 576px) {
    .tc_container {
      padding: 3rem 1rem;
    }
    
    .tc_subcontainer {
      width: 100%;
    }
    
    .tc_heading {
      font-size: 24px;
      font-weight: 550;
      text-align: center;
      padding-bottom: 1rem;
    }
  
    .tc_points {
      padding: 0.25rem 0;
      align-items: center;
    }
  
    .join_diploma {
      padding: 0.45rem 0.75rem;
      font-weight: 500;
      font-size: 15px;
      width: 100%;
    }
  }