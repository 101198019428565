.navbarContainer {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbarMidContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  color: #111111;
  font-weight: 400;
}

.navbarOption {
  cursor: pointer;
}

.selected {
  border-bottom: 1.5px solid #111111;
}

.loginButton {
  outline: none;
  background: #f99c38;
  border: none;
}

.loginButton {
  border: 1.5px solid #000;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.mobileLoginContainer {
  display: none;
}

@media screen and (max-width: 786px) {
  .mobileLoginContainer {
    display: block;
  }

  .navbarMidContainer {
    display: none;
  }

  .loginButtonContainer {
    display: none;
  }
}
