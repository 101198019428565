.searchBoxContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: absolute;
  top: 84vh;
}

.search {
  border: 1.5px solid #000;
  outline: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  height: 50px;
  font-size: 15px;
  width: 70%;
}

.jobDescriptionContainer {
  padding: 5rem 2rem 3rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.inputBoxContainer {
  padding: 1rem;
  width: 100%;
  background: #f8f8f8;
  width: 60%;
  display: flex;
  align-items: start;
  justify-content: start;
  border: 1.25px solid #000;
  border-radius: 5px;
  flex-direction: column;
  gap: 1rem;
}

.textarea {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  scrollbar-width: 0;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.reportContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  align-items: center;
  justify-content: center;
}

.reportSubContainer {
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 0.25rem;
}

.reportHeading {
  font-size: 48px;
  font-weight: 400;
}

.reportContentContainers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  gap: 1rem;
  padding: 10px;
}

.reportContentContainer {
  display: flex;
  flex-direction: column;
  background: #f5fbff;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 0.5rem 0.25rem 0.5rem 1rem;
  align-items: start;
  width: 100%;
  justify-content: start;
  gap: 2rem;
  height: 200px;
}

.reportContentContainer:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.reportContentContainer:nth-child(2) {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.reportContentContainer:nth-child(3) {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.reportContentContainer:nth-child(4) {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.reportContentContainer:nth-child(5) {
  grid-column: 3 / 5;
  grid-row: 1 / 3;
  height: 420px;
}

.reportContentHeading {
  font-size: 16px;
  color: #484848;
}

.reportContent {
  font-size: 58px;
  font-weight: bold;
  color: #191919;
}

.advancedReportContainers {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-wrap: wrap;
  gap: 1rem;
}

.advancedReportContainer {
  background: #f5fbff;
  gap: 1.5rem;
  width: 30%;
  border: 1px solid #000;
  padding: 1rem 0.5rem;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  font-size: 17px;
}

.advancedReportContainer > div:nth-child(1) {
  color: #484848;
}

.advancedReportContainer > div:nth-child(2) {
  color: #191919;
}

.advanceReportContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loginContainer {
  position: absolute;
  z-index: 100;
  display: flex;
  padding: 0.5rem 0.5rem;
  border-radius: 2px;
  width: 45vw;
  left: 25%;
  background: #fff;
  top: 50%;
  align-items: center;
  font-size: 24px;
  color: #191919;
  justify-content: space-between;
  border: 1px solid #000;
}

.no_select {
  filter: blur(3px);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
