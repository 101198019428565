.infoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.infoSubContainer {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 60%;
  background-color: #0778bd;
  padding: 3rem 1rem 1rem 1rem;
  gap: 1rem;
}

.infoHeading {
  color: #fff;
  font-size: 40px;
  line-height: 1.15;
  width: 95%;
}

.getStartedButton {
  width: 250px;
  background-color: #f8e9d5;
}

.message {
  font-size: 14px;
  font-weight: 500;
  width: 120px;
  text-align: center;
}

.animationbox {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 57%;
  left: 12%;
  transform: translate(-50%, -50%) rotate(-20deg);
}

.message_arrow {
  position: absolute;
  top: 60%;
  left: 30%;
  width: 100px;
}

.highlight_animation {
  animation: fadeIN 2s infinite ease-in-out;
}

@keyframes fadeIN {
  0%,
  100% {
    opacity: 0.6;
    transform: translate(-50%, -50%) rotate(-10deg) scale(0.97);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(-10deg) scale(1);
  }
}
