.infoContainer {
  padding: 2rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  /* padding-bottom: 6rem; */
}

.infoContentContainer {
  width: 80%;
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
}

.infoContentHeading {
  color: #262626;
  font-size: 30px;
  font-weight: 400;
}

.infoContentSubContainer {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; /* Flex wrap added */
}

.infoContent {
  width: 25%;
  position: relative;
  margin-bottom: 1.5rem; /* Added margin for better spacing in mobile view */
}

.infoNumber {
  color: #ebebeb;
  font-size: 130px;
  font-weight: 400;
  z-index: 1;
}

.infoText {
  color: #5d5d5d;
  z-index: 2;
  font-size: 20px;
  width: 80%;
  position: absolute;
  top: 40%;
  right: -2%;
}

.paddingBottom {
  height: 6rem;
  width: 100%;
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0.1%, rgba(255, 255, 255, 1) 99.9%); */
}


/* Mobile view (up to 600px) */
@media screen and (max-width: 600px) {
  .infoContentContainer {
    width: 100%;
    padding: 0rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .infoContentHeading {
    font-size: 24px;
  }

  .infoContent {
    width: 100%;
    text-align: center;
  }

  .infoNumber {
    font-size: 80px;
  }

  .infoText {
    font-size: 16px;
    width: 100%;
    top: 50%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Tablet view (601px to 1024px) */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .infoContentContainer {
    width: 80%;
  }

  .infoContentHeading {
    font-size: 28px;
  }

  .infoContent {
    width: 45%;
  }

  .infoNumber {
    font-size: 100px;
  }

  .infoText {
    font-size: 18px;
    width: 90%;
    top: 45%;
    right: -5%;
  }
}

/* Desktop view (above 1024px) */
@media screen and (min-width: 1025px) {
  .infoContentContainer {
    width: 70%;
  }

  .infoContentHeading {
    font-size: 30px;
  }

  .infoContent {
    width: 25%;
  }

  .infoNumber {
    font-size: 130px;
  }

  .infoText {
    font-size: 20px;
    width: 80%;
    top: 40%;
    right: -2%;
  }
}
