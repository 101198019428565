.offeringContainer {
  display: flex;
  flex-direction: column;
  padding: 8rem 2rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;
}

.offeringSubContainer {
  display: flex;
  width: 90%;
  align-items: start;
  justify-content: space-around;
}

.offeringContentContainer {
  display: flex;
  flex-direction: column;
  width: 38%;
}

.offeringMinHeading {
  font-size: 14px;
  font-weight: 500;
}

.offeringContentHeading {
  font-size: 39px;
  line-height: 1.15;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.offeringContentSubHeading {
  font-size: 20px;
  line-height: 1.25;
  color: #5d5d5d;
  font-weight: 400;
}

.offeringImage {
  width: 51%;
  height: 85vh;
  border-radius: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.bgColor1 {
  background-color: #e8ae40;
}

.bgColor2 {
  background-color: #28af7e;
}

.bgColor3 {
  background-color: #983424;
}

.color1 {
  color: #e8ae40;
}

.color2 {
  color: #28af7e;
}

.color3 {
  color: #983424;
}

.offeringPoints {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  justify-content: start;
}

.offeringPoint {
  color: #262626;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

.planOptionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  gap: 2rem;
}

.planHeading {
  font-size: 45px;
  font-weight: 400;
}

.planCardsContainer {
  display: flex;
  align-items: start;
  gap: 2rem;
  justify-content: space-between;
}

.planCard {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  height: 300px;
  width: 33.33%;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.headingContainer > span {
  font-size: 16px;
  color: #262626;
}

.heading {
  font-size: 20px;
  font-weight: 500;
  color: #262626;
}

.planCardPointsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #5d5d5d;
  font-size: 18px;
  width: 90%;
}

.planPoints {
  display: flex;
  align-items: start;
  padding: 0;
  margin: 0;
  justify-content: start;
  gap: 0.5rem;
}

.marker {
  margin-top: 8px;
  min-width: 8px;
  min-height: 8px;
  background: #d9d9d9;
}

@media screen and (max-width: 768px) {
  .offeringSubContainer {
    width: 100%;
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .offeringContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 1rem;
  }

  .offeringContentHeading {
    font-size: 36px;
  }

  .offeringContentSubHeading {
    font-size: 20px;
  }

  .offeringPointCOntent {
    font-size: 20px;
  }

  .offeringImage {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .offeringContainer {
    padding: 0rem 2rem;
    padding-bottom: 4rem;
    gap: 5rem;
  }
}

@media screen and (max-width: 564px) {
  .offeringContentHeading {
    font-size: 34px;
  }

  .offeringContentSubHeading {
    font-size: 16px;
  }

  .offeringPointCOntent {
    font-size: 16px;
  }
}
