.bannerContainer {
  padding-bottom: 6rem;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  position: relative;
  min-height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.navbar {
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.navbarOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  cursor: pointer;
  font-weight: 400;
  color: #191919;
}

.navbarOption {
  padding-bottom: 0.25rem;
}

.loginButton {
  border: 1.5px solid #191919;
  background: transparent;
  outline: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: 100px;
  border-radius: 5px;
  font-size: 14.5px;
  font-weight: 450;
}

.subBannercontainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 3rem 1rem;
  gap: 1rem;
}

.bannerHeading {
  font-size: 78px;
  line-height: 1.15;
  font-weight: 400;
}

.bannerSubHeading {
  padding: 0 12px;
  font-size: 22px;
}
