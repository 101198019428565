.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-content {
  background-color: white;
  width: 400px;
  padding: 0px 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.signinheading {
  margin: 0;
  padding: 0;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid #e2e2e2;
  padding: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
}

.modal-body {
  padding: 2.5rem 0 2.5rem 0;
}

.loginButtons {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;
  }

  .loginButton {
    width: 100%;
    padding: 0.75rem 1rem;
    color: #000;
    font-weight: bold;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border: 2px solid #191919;
    border-radius: 10px;
    transition: ease-in-out 0.3s;
    text-decoration: none;
  }
  
  .loginButton:hover {
    color: #0176bf;
    border: 2px solid #0778bd;
    background-color: transparent;
  }
  
  .loginButton > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
  }
